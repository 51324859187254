import React, { useEffect, useState } from "react";
import logoText from "../../assets/images/logo-text.svg";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router";
import { TeamLogo } from "./components/Fixture";

const TeamPlayerForm = ({ id }) => {
  const config = useSelector((state) => state.config);
  
  const navigate = useNavigate();
  
  const [loading,setLoading ] = useState(false)
  
  
  const [teamInfo, setTeamInfo] = useState({
    name: "",
    shortName: "",
    logo: null,
  });

  const [players, setPlayers] = useState([
    {
      name: "",
      number: "",
      position: "",
      phone: "",
    },
  ]);

  const handleTeamChange = (e) => {
    const { name, value, files, type } = e.target;

    setTeamInfo((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handlePlayerChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPlayers = [...players];
    updatedPlayers[index][name] = value;
    setPlayers(updatedPlayers);
  };

  const addPlayer = () => {
    setPlayers([
      ...players,
      {
        name: "",
        number: "",
        position: "",
        phone: "",
      },
    ]);
  };

  const deletePlayer = (e) => {
    const { id } = e.target.dataset;

    const copy = [...players];
    const newArray = copy.filter((player, index) => index != id);

    console.log(id);

    setPlayers(newArray);
  };

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    const formData = new FormData(e.target);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const res = await fetch(
      `${config.base_url}/api/register.php `,
      requestOptions
    );

    const data = await res.json()
    
    console.log(data);

    if(data.status){
      let message  = 'Registration Successful. '
      if(data.mail_sent){
        message+=' Check your email for your registration ID'
      }
      alert(message)
      navigate(`/${id}`)
    }else{
      setLoading(false)
      alert('Registration failed. Try again')

    }

  };

  const data = {
    competition_data: {
      name: "",
      logo: ",",
    },
    team_schema: {
      title: "Team Details",
      fields: [
        {
          label: "Team name",
          type: "text",
          form_name: "team_name",
        },

        {
          label: "Team short name",
          type: "text",
          form_name: "team_short_name",
        },
        {
          label: "Team Logo",
          type: "file",
          form_name: "team_logo",
        },
        {
          label: "Team Admin Name",
          type: "text",
          form_name: "team_admin_name",
        },
        {
          label: "Team Admin Email",
          type: "email",
          form_name: "team_admin_email",
        },
        {
          label: "Team Gender",
          type: "select",
          options: [
            { full: "male", short: "male" },
            { full: "female", short: "female" },
          ],
          form_name: "team_gender",
        },
      ],
    },
    player_schema: {
      title: "Team Details",
      fields: [
        {
          label: "Player name",
          type: "text",
          form_name: "player_name",
        },

        {
          label: "Jersey number",
          type: "text",
          form_name: "jersey_number",
        },
        {
          label: "Position",
          type: "select",
          options: [
            { full: "Goal Keeper", short: "GK" },
            { full: "Defender", short: "DF" },
            { full: "Midfielder", short: "MF" },
          ],
          form_name: "player_position",
        },
        {
          label: "Contact",
          type: "text",
          form_name: "player_contact",
        },
      ],
    },
  };

  return (
    <div className="w-full max-w-2xl mx-auto">
      {/* <CardHeader>
          <CardTitle>Team and Player Registration</CardTitle>
        </CardHeader> */}
      <div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Team Information Section */}
          <div className="grid gap-4 p-4">
            <h3 className="text-lg font-semibold">{data.team_schema.title}</h3>
            <input type="hidden" name="id" value={id} id="" />

            {data.team_schema.fields.map((field, index) => {
              return (
                <label className="form-control w-full" key={index}>
                  <label htmlFor="" className="label">
                    <label
                      htmlFor=""
                      className="label-text text-base text-base-100"
                    >
                      {field.label}
                    </label>
                  </label>
                  {(field.type == "text" ||
                    field.type == "email") && (
                      <input
                        type={field.type}
                        name={field.form_name}
                        className="input bg-white w-full"
                        value={teamInfo[field.form_name]}
                        onChange={() => {}}
                        placeholder={field.label}
                        required
                      />
                    )}

                  {field.type == "file" && (
                    <input
                      type="file"
                      name={field.form_name}
                      className="file-input bg-white"
                      onChange={() => {}}
                      placeholder="Team Logo"
                      accept="image/*"
                    />
                  )}

                  {field.type == "select" && (
                    <select
                      name={field.form_name + ""}
                      className="input bg-white select"
                      onChange={(e) => {}}
                      required
                    >
                      <option disabled default>
                        Choose position
                      </option>

                      {field.options.map((option, oindex) => (
                        <option value={option.short} key={oindex}>
                          {option.full}
                        </option>
                      ))}
                    </select>
                  )}
                </label>
              );
            })}
          </div>

          {/* Players Section */}
          <div className="p-4">
            <h3 className="text-lg font-semibold">Players</h3>
          </div>

          <div className="gap-4 p-4 grid md:grid-cols-2  ">
            {players.map((player, index) => (
              <div key={index} className="grid  gap-2 border-2 p-2 rounded-xl">
                {data.player_schema.fields.map((field, pindex) => {
                  return (
                    <label className="form-control w-full" key={pindex}>
                      <label htmlFor="" className="label">
                        <label
                          htmlFor=""
                          className="label-text text-base text-base-100"
                        >
                          {field.label}
                        </label>
                      </label>
                      {field.type == "text" && (
                        <input
                          name={field.form_name + "[]"}
                          className="input bg-white "
                          //   value={player.name}
                          onChange={(e) => {}}
                          placeholder={field.label}
                          required
                        />
                      )}
                      {field.type == "select" && (
                        <select
                          name={field.form_name + "[]"}
                          className="input bg-white select"
                          onChange={(e) => {}}
                          required
                        >
                          <option disabled default>
                            Choose position
                          </option>

                          {field.options.map((option, oindex) => (
                            <option value={option.short} key={oindex}>
                              {option.full}
                            </option>
                          ))}
                        </select>
                      )}
                    </label>
                  );
                })}

                {/* <input
                  name="number[]"
                  className="input bg-white"
                  value={player.number}
                  onChange={(e) => handlePlayerChange(index, e)}
                  placeholder="Jersey Number"
                  type="text"
                  required
                />
                <select
                  name="position[]"
                  className="input bg-white select"
                  value={player.position}
                  onChange={(e) => handlePlayerChange(index, e)}
                  required
                >
                  <option disabled default>
                    Choose position
                  </option>
                  <option>Goal Keeper</option>
                  <option>Defender</option>
                  <option>Midfielder</option>
                  <option>Striker</option>
                </select>
                <input
                  name="phone[]"
                  className="input bg-white "
                  value={player.phone}
                  onChange={(e) => handlePlayerChange(index, e)}
                  placeholder="Phone Number"
                  type="tel"
                  required
                /> */}

                {players.length > 1 && (
                  <div className=" flex justify-end">
                    <span
                      className="btn border-none bg-red-500 text-white"
                      data-id={index}
                      onClick={deletePlayer}
                    >
                      Delete
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="p-4">
            <button
              type="button"
              variant="secondary"
              onClick={addPlayer}
              className="btn w-full bg-slate-300 text-base-100 hover:text-white"
            >
              Add Another Player
            </button>
          </div>

           <button type="submit" className="w-full btn" disabled={loading}>
            {loading ? 'Submitting ...' : 'Submit Team Registration'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default function Register() {
  const base_url = useSelector((state) => state.config.base_url);
  const location = useLocation();
  const navigate = useNavigate();

  let path = location.pathname.split("/");
  let id = path[2];

  console.log(id)

  const [loadingPage, setLoadingPage] = useState(true);
  const [name, setName] = useState("");
  const [logo, setLogo] = useState("");
  const [error, seError] = useState(false);

  useEffect(() => {
    fetch(`${base_url}/api/get_config.php?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        
        if(data.status){
          setName(data.name);
          setLogo(data.logo);
          setLoadingPage(false);
        }else{
          alert('Invalid competition ID')
          navigate('/')
        }
      });
  }, []);

  return (
    <>
      {loadingPage ? (
        <div className="h-screen bg-white flex flex-col justify-center gap-3">
          <span className=" self-center loading loading-dots loading-lg bg-base-100"></span>
          <span className="text-base-100 self-center">
            Loading Registration Form
          </span>
        </div>
      ) : (
        <div
          data-theme="versuz"
          className="h-dvh bg-slate-100 text-base-100 pt-4 overflow-scroll pb-[200px]"
        >
          <div className="flex flex-col items-center gap-3 p-4">
            <img
              src={`${base_url}uploads/${logo}`}
              className="w-[150px] aspect-square rounded-full shadow-lg"
              alt=""
            />
            <div>
              <h1 className="text-xl font-pilat text-center font-bold">
                {name}
              </h1>
              <p className="text-center">Register your team</p>
            </div>
          </div>

          <TeamPlayerForm id={id} />

          <footer className=" fixed bottom-0 left-0 bg-base-100 p-4 w-full">
            <p className="text-lime-500 text-center justify-center font-bold flex gap-2 text-sm">
              Powered by{" "}
              <img src={logoText} className="w-[70px] md:w-[80px]" alt="" />
            </p>
          </footer>
        </div>
      )}
    </>
  );
}
