import React, { useEffect, useState } from "react";
import admin from "../../../assets/images/admin-2.png";
import sportfolio from "../../../assets/images/portfolio.png";
import data from "../../../assets/images/data.png";

function Solution({ title, body, details, src,index }) {
  const [image, setImage] = useState(src);
  useEffect(() => {
    setImage(src);
  }, []);
  return (
    <div className="bg-base-100 rounded-[20px] p-[20px]  mx-[0px]  ">
      <img
        src={src}
        alt=""
        className=" bg-purple-100 rounded-[20px] w-full mb-5 h-[200px] "
      />
      <p className="text-purple-100 mb-[10px] font-bold text-xl text-center">
        {title}
      </p>
      <p className="text-purple-100   text-justify lg:text-center ">{body}</p>
      <div className=" flex">
        <button
          onClick={() => document.getElementById(`my_modal_${index}`).showModal()}
          className=" mx-auto btn text-white rounded-full  mt-[25px] px-[20px] py-[5px] bg-purple font-bold"
        >
          {" "}
          Learn more
        </button>
      </div>

      <dialog id={`my_modal_${index}`} className="modal modal-top sm:modal-middle ">
        <div className="modal-box  ">
          <img
            src={image}
            alt=""
            className=" bg-purple-100 rounded-[20px] w-full mb-5 h-[200px] "
          />

          <h3 className="font-bold text-2xl text-center text-white">{title}</h3>
          
          <p className="py-4 text-white leading-normal text-lg text-justify">
            {details}
          </p>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  );
}

function Modal() {
  return (
    <>
      {/* Open the modal using document.getElementById('ID').showModal() method */}
      <button className="btn">open modal</button>
    </>
  );
}

export default function Solutions() {
  const [solutions, setSolutions] = useState([
    {
      title: "Collaborative Admin Control",
      body: "Our intuitive dashboard supports multiple users, improves real time management core aspects and  tracking of key metrics ",
      details: "Our dashboard is simple to use and designed to help teams work together effectively. It allows multiple people to access it at the same time, with clear roles and permissions to ensure everyone focuses on what they need to do. With real-time updates, users can see changes as they happen, get alerts when something needs attention, and track progress easily. It also displays important information like performance, finances, and engagement in easy-to-read charts and graphs. This makes it a powerful tool for sports admins to stay organized, make quick decisions, and achieve goals efficiently.",
      src: admin,
    },
    {
      title: "Sportfolio",
      body: "Showcase your team’s or organization’s past performances, key achievements, and  growth journey with our comprehensive Sportfolio feature. ",
      details: "Showcase your team’s or organization’s journey with our Sportfolio feature, designed to highlight your past performances, key achievements, and growth milestones. Whether it's championship victories, player development, or audience engagement milestones, Sportfolio helps you present your success story in a visually compelling and organized manner. Build trust with sponsors, attract new supporters, and celebrate your progress with a comprehensive portfolio that showcases your impact and growth over time.",
      src: sportfolio,
    },
    {
      title: "Data Management  & Analytics",
      body: "Get on demand access to valuable data insight, track and analyze key metrics to measure success and optimize for future growth.",
      details: "Gain on-demand access to valuable data insights with our platform, allowing you to track and analyze key metrics to measure your success. Whether it's performance data, audience engagement, or financial outcomes, our tools help you understand what’s working and identify areas for improvement. With this data at your fingertips, you can make informed decisions and optimize strategies to drive future growth and success.",
      src: data,
    },
  ]);
  return (
    <section
      data-theme="versuz"
      className="solutions bg-purple-100 rounded-[40px] px-[20px] md:px-[50px] py-[80px] max-w-[1400px] mx-auto mt-[100px] text-base-100 "
    >
      <h1 className="text-3xl text-center font-semibold hidden md:block">
        {" "}
        Powerful Solutions for <br />{" "}
        <span className="text-purple">Modern</span> Sports Management
      </h1>
      <h1 className="text-3xl text-center font-semibold md:hidden">
        Explore <span className="text-purple">Modern</span> Sports Management
        Solutions <br />{" "}
      </h1>

      <p className="text-base-100 max-w-[606px] text-cemter mx-auto mt-[18px]">
        Streamline every aspect of your competition—from team registrations and
        match scheduling to real-time score updates and automated notifications.{" "}
      </p>

      <div className="grid md:grid-cols-2 lg:grid-cols-3  mt-[50px] gap-[22px]">
        {solutions.map((sol, index) => (
          <Solution
            key={index}
            index={index}
            title={sol.title}
            body={sol.body}
            details={sol.details}
            src={sol.src}
          />
        ))}
      </div>
    </section>
  );
}
