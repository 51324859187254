import React, { useState } from "react";
import maleIcon from "../../assets/images/male-icon.png";
import femaleIcon from "../../assets/images/female-icon.png";

const Stat = ({ data }) => {
  return (
    <div className="col-span-2">
      <p className="border p-2 px-3 rounded-t-xl bg-base-100 text-white flex justify-between">
        <span className="self-center">{data.title}</span>
        <span className="font-bold text-base-100 bg-lime-500 rounded-full w-[25px] aspect-square flex justify-center text-xs">
            <span className="text-center self-center">{data.rank}</span>
        </span>
      </p>

      {data.stats.map((stat, index) => {
        return (
          <p
            key={index}
            className="border p-2 px-3  text-base-100  flex justify-between text-sm"
          >
            <span>{stat.name}</span>{" "}
            <span className="font-bold">{stat.count}</span>
          </p>
        );
      })}
    </div>
  );
};

export default function PlayerDetails() {
  const [name, setName] = useState(["Owolana", "Ayomide"]);
  const [position, setPosition] = useState("Defender");
  const [team, setTeam] = useState("Manchester United");
  const [teamLogo, setTeamLogo] = useState("Manchester United");

  const [coreStats, setCoreStats] = useState([
    { title: "Appearances", count: 5 },
    { title: "Minutes Played", count: 10 },
    { title: "Goals", count: 5 },
    { title: "Assists", count: 5 },
  ]);

  const [stats, setStats] = useState([
    {
      title: "Defence",
      rank: "7.2",
      stats: [
        {
          name: "Tackles ",
          count: 3,
        },
        {
          name: "Interceptions",
          count: 10,
        },
        {
          name: "Duels Won",
          count: 3,
        },
        {
          name: "Clean sheets",
          count: 2,
        },
      ],
    },
    {
      title: "Attack",
      rank: "8.3",
      stats: [
        {
          name: "Total Shots ",
          count: 20,
        },
        {
          name: "Shots on target",
          count: 10,
        },
        {
          name: "Progressive carries",
          count: 6,
        },
        {
          name: "Chances created",
          count: 8,
        },
      ],
    },
    {
      title: "Discipline",
      rank: "3.4",
      stats: [
        {
          name: "Fouls",
          count: 8,
        },
        {
          name: "Yellow Cards",
          count: 4,
        },
        {
          name: "Red cards",
          count: 1,
        },
      ],
    },
  ]);

  const data = {
    player_details: {
      name: "Owolana Ayomide",
      position: "Defender",
      team: {
        name: "Manchester United",
        logo: "",
      },
    },
    core_stats: [
      { title: "Goals", count: 5 },
      { title: "Assists", count: 10 },
      { title: "Goals", count: 5 },
      { title: "Goals", count: 5 },
    ],
  };
  return (
    <div className="mt-[50px] md:mt-[80px] max-w-[1200px] mx-auto md:grid grid-cols-6 gap-4">
      <div className="bg-gray-100 p-6  col-span-2 rounded-lg shadow-lg">
        <img
          className="w-full max-w-full aspect-square"
          src={maleIcon}
          alt=""
        />
        <div className=" w-full flex flex-col justify-start gap-4">
          <div className="flex justify-between mt-3">
            <p className="text-base-100 font-bold">
              <span className="text-2xl">{name[0] && name[0]}</span>
              <br />
              <span className="text-4xl">{name[1] && name[1]}</span>
            </p>

            {/* <p className="text-5xl text-base-100 font-extrabold">
                    37
                  </p> */}
          </div>
          <div>
            <p className="text-base text-base-100">{team}</p>
            <p className="text-base text-base-100">{position}</p>
          </div>

          {/* <div className=' mt-1'>
                    <p className='self-center text-xl text-base-100 mb-2'>Socials</p>
                    <div className='flex gap-3 '>
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />
                        <img className='w-[30px] aspect-square rounded-full bg-base-100' src="" alt="" />

                    </div>
                </div> */}
        </div>
      </div>

      <div className=" col-span-4">
        <div className=" my-5 md:my-0 grid grid-cols-2  md:grid-cols-4 gap-2">
          {coreStats.map((stat, index) => {
            return (
              <div className="border p-3 rounded-lg" key={index}>
                <p className="text-base-100">{stat.title}</p>
                <p className="text-4xl font-bold">{stat.count}</p>
              </div>
            );
          })}
        </div>

        <div className="grid md:grid-cols-4 p-2 mt-3 gap-4 md:gap-2">
          {stats.map((stat, index) => {
            return <Stat key={index} data={stat} />;
          })}
        </div>
      </div>
    </div>
  );
}
